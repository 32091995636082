.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 100%;
  overflow: auto;

  .errorImageBox {
    font-size: 12rem;
    color: var(--color-brand-blue);
  }

  .errorMessageBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .errorMessageText {
      h1 {
        color: #ff5a60;
      }
    }
  }

  .errorMessageControls {
    button {
      margin: 0 15px 10px 10px;
      border-radius: 9px;
      border: none;
    }
  }
}
