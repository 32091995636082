@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url("fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato;
  font-weight: 500;
  src: url("fonts/Lato/Lato-Medium.ttf");
}

@font-face {
  font-family: Lato;
  font-weight: 600;
  src: url("fonts/Lato/Lato-Semibold.ttf");
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  src: url("fonts/Lato/Lato-Bold.ttf");
}

@font-face {
  font-family: Lato;
  font-weight: 900;
  src: url("fonts/Lato/Lato-Black.ttf");
}
