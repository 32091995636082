.container {
  position: relative;
  width: 100%;
  z-index: 2;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 4;
  }

  .content_container_blur {
    filter: blur(2px);
    margin-top: -40px;
  }

  .loader_fixed {
    position: sticky;
    z-index: 3;
    top: 50%;
    display: flex;
    justify-content: center;
  }
}
