.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1rem;
  }
  .content {
    width: 20%;
    .invalid_data {
      color: red;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .form {
      label {
        font-weight: 500;
      }
    }

    .button {
      margin-top: 20px;
      width: 100%;
      height: 50px;
    }

    .invalid {
      background-color: #7396f3;
      color: white;
    }
  }
}
