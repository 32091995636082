.root {
  background: var(--color-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 12px;
  position: relative;

  .table {
    height: 64vh;
  }

  .tableWithBreadcrumbs {
    height: 57vh;
  }

  @media (max-height: 768px) {
    .table {
      height: 54vh;
    }

    .tableWithBreadcrumbs {
      height: 46vh;
    }
  }

  .notFindMessage {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%);
  }

  .headerBorder::after {
    content: '';
    display: block;
    position: absolute;
    height: 9.6px;
    border-right: 1.3px solid rgba(106, 122, 152, 0.15);
    right: 4px;
  }

  .pinIcon {
    margin-right: 4px;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 12px;
    padding: 16px;
    background: var(--color-table-head);
    border-radius: 8px;

    .itemsPerPage {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: var(--color-accent);
    }

    .itemsSelectPerPage {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      div {
        margin-left: 8px;
      }
    }

    .paginationItem {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: var(--color-tag-text);
    }

    .paginationItemSelected {
      background-color: var(--color-accent);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      color: white;
    }
  }

  @media (max-width: 768px) {
    .pagination {
      flex-direction: column;
      margin-top: 8px;
      padding: 6px 0;

      .paginationItem {
        font-size: 14px;
        line-height: 10px;
      }

      & :nth-child(2) {
        padding-top: 4px;
      }
    }
  }

  @media (max-width: 1024px) {
    .pagination {
      flex-direction: column;
      margin-top: 8px;
      padding: 6px 0;

      .paginationItem {
        font-size: 14px;
        line-height: 10px;
      }

      & :nth-child(2) {
        padding-top: 4px;
      }
    }
  }

  .dropdown {
    position: relative;
    right: 100px;
  }

  .modifyTable {
    width: 20px;
    position: relative;
    right: 3px;
    top: 2px;

    button {
      margin: 0 !important;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.sortButton {
  left: 5px;

  &:hover {
    background-image: url('../../icons/height.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.tableMainHeader {
  &:hover {
    .openModifyMenu {
      visibility: visible;
      cursor: pointer;
    }
  }
}

/* Following styles are duplicated in main.css to be applied to draggable header */

.customizeTableMenu {
  padding: 32px 24px !important;
  border-radius: 50px;
  width: 384px;

  .title {
    font-family: Golos, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    :global .ant-checkbox-wrapper {
      margin: 7px 10px;
      max-width: fit-content;
    }

    .checkboxLabel {
      font-family: Golos, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1b1d21;
    }
  }

  .customizeMenuFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;

    .resetButton {
      width: 172px;
      margin-right: 8px;
      border: 1px solid #e2edff;
      border-radius: 12px;
      color: var(--color-tag-text);
      text-transform: capitalize;
    }
  }
}

.modifyTableMenu {
  padding: 22px 18px !important;
  border-radius: 50px;
  width: 220px;
  height: 220px;

  li {
    margin-top: 2px;

    &:hover {
      background: #f8f9fb;
      border-radius: 6px;
    }
  }

  .contextMenuItem {
    display: flex;
  }

  span {
    width: 160px;
    margin-left: 13px;
    font-family: Golos, sans-serif;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    position: relative;
    color: #25314f;
  }
}

.hideIcon {
  visibility: hidden !important;
}

.openModifyMenu {
  background: none;
  border: none;
  opacity: 0.5;
  height: 20px;
  visibility: hidden;

  &:focus {
    outline: none !important;
  }
}

.tableCell {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-text-primary);
}

.tableCellWrapped {
  white-space: normal;
}

.tableCellUnwrapped {
  white-space: nowrap;
}

.tableHeaderLabel {
  font-family: Golos, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--color-text-secondary);
  line-height: 16px;
  margin: 0;
}

.noPadding {
  padding: 0;
}

/* end of styles duplication */
