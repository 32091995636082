:root {
  --color-brand-blue: #2b3858;
  --color-accent: #2f80ed;
  --color-error: #ff5a60;
  --color-white: #ffffff;
  --color-background: #f5f5f5;
  --color-table-head: #f8f9fb;

  --color-text-primary: #25314f;
  --color-text-secondary: #818c99;
  --color-tag-text: #788fb1;
  --color-tag-background: #f0f3f6;

  --color-menu-item: #7b819b;
  --color-table-nav: #e9eef7;
  --color-success: #01db9d;

  --color-list-item: #d7d9e1;
  --color-yellow-gradient: linear-gradient(
      47.42deg,
      #ffb97a -30.93%,
      #ffe184 92.71%
    ),
    linear-gradient(
      34.31deg,
      #5433ff -145.62%,
      #20bdff -18.75%,
      #a5fecb 108.11%
    );
}
