@import "./lato.css";
@import "./golos.css";

body {
    font-family: "Lato", "Golos";
}

.validationErrorText {
    font-size: 12px;
    color: #ff5a60;
}

.ant-notification-notice-error {
    width: 81vw;
    border: 2px solid #ff5a60;
    border-radius: 10px;
}

.ant-notification-notice-warning {
    width: 81vw;
    border: 2px solid #f2994a;
    border-radius: 10px;
}

.ant-notification-notice-success {
    width: 81vw;
    border: 2px solid #01db9d;
    border-radius: 10px;
}

.ant-btn-default {
    background: #f6f6f9;
    color: #2f80ed;
}

.ant-notification-notice {
    padding-bottom: 25px;
}

.ant-picker-footer-extra {
    padding: 16px;
}

.ant-picker-panel-container {
    text-transform: capitalize;
    border-radius: 16px;
    color: #4c638e;
}

button.ant-picker-month-btn {
    color: #4c638e;
    text-transform: capitalize;
}

button.ant-picker-year-btn {
    color: #4c638e;
}

table.ant-picker-content > thead > tr > th {
    color: #4c638e;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #fafafa;
}

.btn-primary {
    font-family: Golos;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    border-radius: 12px !important;
    text-transform: none;
    background-color: var(--color-accent);
    box-shadow: none;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
.ant-select-selector
input {
    cursor: default;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    opacity: 0.5;
    background-color: var(--color-accent);
    color: #ffffff;
}

.ant-input,
.ant-input-number,
.ant-input-number-input,
textarea.ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    transition: none;
    border-radius: 8px ;
}

.inputWithError {
    border: 1px solid red;
    border-radius: 8px;
}

.inputWithError div:first-child:hover {
    border-color: #d9d9d9;
}

.inputWithError * {
    border-color: red !important;
    outline: none;
    border-radius: 8px;
}

.inputWithError.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid red !important;
}

.btn-primary:hover {
    background-color: #40a9ff;
}

.ant-btn-primary:focus {
    background: var(--color-accent);
}

.btn-outlined-primary {
    font-family: Lato;
    padding: 7px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: none;
    color: var(--color-accent);
    background: #ffffff;
    border-radius: 12px;
    box-shadow: none;
}

.btn-text-primary {
    font-family: Lato;
    padding: 7px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: none;
    color: var(--color-accent);
    background: #ffffff;
    border: none;
    box-shadow: none;
}

.btn-outlined-danger {
    font-family: Lato;
    padding: 7px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: none;
    color: var(--color-error);
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid var(--color-error);
    box-shadow: none;
}

.btn-light {
    font-family: Golos;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    border-radius: 12px;
    box-shadow: none;
}

.btn-light:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiTableCell-head {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: var(--color-table-head);
    border: none;
}

thead th:first-child {
    border-radius: 12px 0 0 12px;
}

thead th:last-child {
    padding: 6px;
    border-radius: 0 12px 12px 0;
}

.activeCustomizeButton {
    max-width: 32px;
    padding: 6px;
    background: var(--color-accent);
    border: none;
    border-radius: 6px;
}

.inactiveCustomizeButton {
    max-width: 32px;
    padding: 6px;
    background: none;
    border: none;
    border-radius: 6px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: var(--color-accent);
}

/* context menu */
.MuiPopover-paper {
    border-radius: 16px;
    /* padding: 8px; */
}

/* scroll */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: none;
    border: 1px solid inherit;
}

::-webkit-scrollbar-thumb {
    background: rgba(120, 143, 177, 0.5);
    border-radius: 4px;
    border: none;
}

.customInputLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.07px;
    color: #818c99;
    margin: 19px 0;
}

.customInputLabel div {
    display: flex;
    align-items: center;
}

.customInputLabel div span {
    margin-left: 16px;
    height: 20px;
}

.MuiOutlinedInput-multiline {
    padding: 0;
}

.ant-select-arrow {
    right: 16px;
}

.ant-select-selector {
    height: 44px !important;
}
.ant-select-selector span {
    display: flex;
    align-items: center;
}

.ant-input:disabled,
.ant-input-number-disabled,
.ant-picker.ant-picker-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
.ant-select-selector,
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    cursor: default !important;
    border: none;
    background: none !important;
    color: var(--color-text-primary) !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: inherit;
    cursor: default;
}

.ant-select-multiple .ant-select-selection-item {
    border: none;
    background: inherit;
    cursor: default;
}

.ant-input[disabled]:hover {
    border-bottom: 1px solid rgba(106, 122, 152, 0.15);
}

.ant-input:hover:not(:disabled),
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    cursor: pointer;
    background-color: #f5f5f5;
}

/*.ant-picker.ant-picker-disabled {*/
/*    background-color: none;*/
/*}*/

.ant-picker-input > input[disabled] {
    color: var(--color-text-primary);
}

.ant-input:focus,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    box-shadow: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.ant-input-number .ant-input-number-handler-wrap {
    opacity: 1;
    border: none;
    width: 44px;
}

.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-up-inner:after {
    content: url(./increment.svg);
}

.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-down-inner:after {
    content: url(./decrement.svg);
}

.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-up-inner
svg,
.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-down-inner
svg {
    display: none;
}

.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-up-inner {
    margin-right: 12px;
    padding-top: 4px;
}

.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-down-inner {
    margin-right: 12px;
    padding-bottom: 4px;
}

.ant-input,
.ant-input-number {
    border-radius: 8px;
}

.ant-input-number-handler-up,
.ant-input-number-handler-down {
    border-top: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
.ant-select-selector {
    height: 44px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    padding-right: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
    margin-right: 22px;
}

.ant-select-clear {
    top: 47%;
    width: 20px;
    height: 16px;
    color: #818c99;
    font-size: 16px;
    background: #f5f5f5;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
}

.ant-tabs-tab-btn {
    color: #818c99;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #25314f;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
}

.ant-switch {
    width: 64px;
    height: 32px;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 26px - 2px);
}

.ant-switch-handle {
    width: 22px;
    height: 22px;
}

.ant-switch-handle::before {
    top: 3px;
    right: -2px;
    bottom: -3px;
    left: 2px;
    border-radius: 16px;
}

tr:hover > td {
    background-color: #f5f5f5;
}

.ant-checkbox {
    top: 0.3em;
}

.ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px !important;
}

.ant-checkbox-checked::after {
    border: none;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    height: 18px;
    width: 8px;
    top: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2f80ed;
    border-color: #2f80ed;
}

* {
    scrollbar-color: rgba(120, 143, 177, 0.5) white !important;
    scrollbar-width: 8px !important;
}

main {
    background-color: #f5f5f5;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
    padding-right: 0 !important;
}

/* next styles are duplicated to be available to apply to draggable header */

.customizeTableMenu {
    padding: 32px 24px;
    border-radius: 50px;
    width: 384px;
}

.customizeMenuFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.customizeTableMenu {
    padding: 32px 24px;
    border-radius: 50px;
    width: 384px;
}

.customizeMenuFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.resetButton {
    width: 172px;
    margin-right: 8px;
    border: 1px solid #e2edff;
    border-radius: 12px;
    color: var(--color-tag-text);
    text-transform: capitalize;
}

.modifyTableMenu {
    padding: 32px 24px;

    border-radius: 50px;
    width: 220px;
    height: 220px;
}

.contextMenuItem {
    display: flex;
}

.contextMenuItem span {
    width: 160px;
    margin-left: 13px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    position: relative;
}

.openModifyMenu {
    background: none;
    border: none;
    opacity: 0.5;
    padding-top: 5px;
}

.openModifyMenu:focus {
    outline: none !important;
}

.tableCellUnwrapped {
    white-space: nowrap;
}

.tableCellWrapped {
    white-space: normal;
}

.bg {
    padding: 0 8px;
    background-color: var(--color-tag-background);
    border-radius: 6px;
}

/* end of styles duplication */

.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:after {
    border: none !important;
}

.breadcrumbs {
    background: #f8f9fb;
    border-radius: 8px;
    margin-bottom: 24px;
    color: var(--color-accent);
    padding: 10px 16px;
}

.breadcrumbs.m-top {
    margin-top: 24px;
}

.crumbs {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-accent);
    cursor: pointer;
}

.breadcrumbs-inactive {
    color: #818C99;
    text-overflow: ellipsis;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
}

.columnResizer {
    background-color: red;
    width: 6px;
}

.filter.ant-drawer .ant-drawer-content {
    border-radius: 16px 0px 0px 16px;
}

.filter .ant-drawer-header {
    border: none;
}

.filter .ant-drawer-close {
    top: 20px;
    color: var(--color-text-primary);
}

.filter .ant-drawer-title {
    font-family: Golos;
    padding: 0 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--color-text-primary);
}
.MuiTreeItem-label {
    padding: 10px 0 !important;
}
.MuiTableCell-stickyHeader {
    border: none !important;
}
