.root {
  display: flex;
  justify-content: space-between;
  margin: 28px 0;

  .searchFilterWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .buttonWrapper > * {
    margin-right: 24px;
  }

  .searchForm {
    padding: 4px;
    background: #ffffff;
    border-radius: 12px;
    width: 360px;

    span {
      border: none;
    }

    input {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      border-radius: 0;

      &:hover {
        background: none;
      }
    }
  }

  .btnLabelWithIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-right: 7px;
    }
  }

  .filterBtn {
    margin-left: 24px;
    margin-right: 8px;
    width: 133px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: var(--color-accent);
    background: #ffffff;
    border-radius: 12px;
    border: none;
  }

  .activeFilterBtn {
    @extend .filterBtn;
    box-shadow: 0 2px 9px -2px #006dff;
  }
}

.ant-space {
  display: flex;
}

@media (max-width: 768px) {
  .root {
    margin-top: 0;
    margin-bottom: 6px;
    flex-direction: column;

    .buttonWrapper {
      display: flex;
      justify-content: space-between;
    }

    .searchFilterWrapper {
      margin-top: 12px;
      justify-content: space-between;

      & > div > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .root {
    margin-top: 0;
    margin-bottom: 6px;
    flex-direction: column;

    .searchFilterWrapper {
      margin-top: 12px;
      justify-content: space-between;
    }
  }
}

@media (max-width: 1220px) {
  .root {
    .searchForm {
      width: auto;
    }

    .buttonWrapper > * {
      margin-right: 6px;
    }
    .filterBtn {
      width: auto;
      margin-left: 6px;
    }
  }
}
