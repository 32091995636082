.root {
  margin-bottom: 64px;
  padding: 0 24px 28px 24px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .link {
    color: var(--color-accent);
    border-bottom: 1px solid rgba(106, 122, 152, 0.15);
    display: block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 11px;

    &:hover {
      cursor: pointer;
      color: #0060e2;
    }
  }

  @mixin afterBorder {
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      border-right: 1.3px solid rgba(106, 122, 152, 0.15);
      right: 43px;
      top: 0;
    }
  }

  @mixin selectButton {
    width: 46px;
    border-radius: 0;
    position: relative;
    bottom: 2px;
    height: 32px;
  }

  @mixin inputEditable {
    padding: 6px 0 6px 24px !important;
    border: none;
    position: relative;
    right: 12px;
    color: var(--color-text-primary);
  }

  .actionBlock {
    margin-top: 16px;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    background: #f8f9fb;
    border-radius: 8px;

    button {
      margin-left: 16px;
      padding: 6px 56px;
    }
  }

  .individualAccessRightsForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .expandedIcon {
    order: -1;
    min-height: 64px;
  }

  :global .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }

  .addItemBtn {
    height: 30px;
    position: relative;
    top: 14px;
    right: 10px;
    border-radius: 12px;
  }

  .removeItemBtn {
    font-family: Golos;
    padding: 7px 16px;
    margin: 10px 0 0 auto;
  }

  .accordion {
    margin: 18px 0 0;
    box-shadow: none;
    border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 12px;
    padding-left: 5px;

    &::before {
      display: none;
    }

    .accordionHead {
      display: flex;
      justify-content: space-between;
    }

    .subtitle {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }

    .accordionDetails {
      padding: 24px;
      padding-top: 0;
      border-radius: 12px;
    }
  }

  .title {
    padding-top: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: var(--color-text-primary);
  }

  .main_title {
    width: 100%;
  }

  .inputNonEditable {
    input,
    textarea {
      border-bottom: 1px solid rgba(106, 122, 152, 0.15);
      cursor: default;
      border-radius: 0;
    }
    fieldset {
      border: none !important;
    }
    .select {
      border-bottom: 1px solid rgba(106, 122, 152, 0.15);
    }
    svg {
      display: none;
    }
  }

  .inputEditable {
    input {
      border-radius: 8px;
      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
      &:focus-within {
        border-color: rgba(47, 128, 237, 0.5);
      }
      &:disabled {
        background: #f5f5f5;
        border: none;
      }
    }
    fieldset {
      border: none !important;
    }
    .inputNumber {
      div {
        color: var(--color-text-primary);
        border-radius: 8px;
        @include afterBorder;
      }
      &:focus-within {
        border-color: rgba(47, 128, 237, 0.5);
      }
      .disabled {
        background: #f5f5f5;
        border: none;
      }
    }
    .select {
      &:first-child {
        &:after {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          border-right: 1.3px solid rgba(106, 122, 152, 0.15);
          right: 43px;
          top: 0;
        }
      }
      div {
        color: var(--color-text-primary);
        border-radius: 8px;

        &:hover {
          background-color: #f5f5f5;
        }
      }
      &:focus-within {
        border-color: rgba(47, 128, 237, 0.5);
      }
      .disabled {
        background: #f5f5f5;
        border: none;
      }
    }
    .MuiSelect-select.Mui-disabled {
      background: #f5f5f5;
      border: none;
    }
  }

  .readOnly {
    background: #f5f5f5;
    border: none;
    border-radius: 8px;

    svg {
      display: none;
    }

    &:first-child:after {
      display: none !important;
    }

    div:after {
      display: none !important;
    }
  }

  .editButton {
    font-family: Golos;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid var(--color-accent);
    min-width: 44px;
    margin-right: 24px;
  }

  .filterSelectEditable {
    height: 33px;
    border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 8px;
    position: relative;
    top: 12px;
    &:focus-within {
      border-color: rgba(47, 128, 237, 0.5) !important;
    }
    input {
      @include inputEditable;
      border-radius: 0;
      border-right: 1px solid rgba(106, 122, 152, 0.15);
      &:focus {
        background: none;
      }
    }
    button {
      @include selectButton;
    }
  }

  .filterSelectNonEditable {
    height: fit-content;
    border-bottom: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 0;
    position: relative;
    top: 12px;
    input {
      border: none;
      color: var(--color-text-primary);
    }
  }

  .filterMultiSelectEditable {
    // border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 8px;
    .textField {
      @include afterBorder;
      &:after {
        height: 100%;
      }
    }
    &:focus-within {
      border-color: rgba(47, 128, 237, 0.5) !important;
      .textField {
        &:after {
          border-color: rgba(47, 128, 237, 0.5) !important;
        }
      }
    }
    input {
      @include inputEditable;
      &:focus {
        background: none;
      }
    }
    button {
      @include selectButton;
    }
  }

  .filterMultiSelectNonEditable {
    border-bottom: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 0;
    :global span.MuiChip-label {
      color: var(--color-text-primary) !important;
    }
    input {
      border: none;
    }
  }

  .filterMultiSelect {
    margin-top: 12px;
    height: fit-content;
    :global span.MuiChip-label {
      color: var(--color-text-primary) !important;
      opacity: 100%;
    }
    :global .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
      background: none;
      margin: 0;
      padding-left: 8px;
      font-size: 16px;
      line-height: 20px;
      span {
        padding: 3px !important;
      }
      svg {
        display: none;
      }
    }
  }

  .multiSelect {
    height: fit-content;
    @include afterBorder;
    &:after {
      height: 100%;
    }
    :global span.MuiChip-label {
      color: var(--color-text-primary) !important;
      opacity: 100%;
    }
    :global .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
      background: none;
      margin: 0;
      padding-left: 8px;
      font-size: 16px;
      line-height: 20px;
      span {
        padding: 3px !important;
      }
      svg {
        display: none;
      }
    }
  }

  .multiSelectEditable {
    border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 8px;
    &:focus-within {
      border-color: rgba(47, 128, 237, 0.5) !important;
      &:after {
        border-color: rgba(47, 128, 237, 0.5) !important;
      }
    }
    input {
      display: none;
    }
  }

  .multiSelectNonEditable {
    border-bottom: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 0;
    :global span.MuiChip-label {
      color: var(--color-text-primary) !important;
    }
    &:after {
      border: none;
    }
  }

  hr {
    border: 0;
    border-bottom: 2px dashed #ccc;
    background: white;
  }

  .form {
    padding: 20px 0;
  }

  .tabs {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin: 0px -24px;
    border-bottom: 1px solid rgba(37, 12249, 79, 0.1);
  }

  .searchForm {
    height: 32px;
    position: relative;
    bottom: 12px;
    div {
      height: 32px;
      margin: 0;

      p {
        margin: 6px 0;
      }
    }

    input {
      border: 1px solid rgba(106, 122, 152, 0.15);
      border-radius: 8px;
      height: 11px;
      position: relative;
      left: -10px;
      bottom: 10px;
      &:focus-within {
        border-color: rgba(47, 128, 237, 0.5);
      }
    }
    fieldset {
      border: none !important;
      padding: 0 !important;
      height: 100%;
      position: relative;
      bottom: 10px;
    }
    .select {
      border: 1px solid rgba(106, 122, 152, 0.15);
      border-radius: 8px;
      div {
        padding: 2px 6px !important;
        @include afterBorder;
      }
      &:focus-within {
        border-color: rgba(47, 128, 237, 0.5);
      }
    }
  }

  .multilineText {
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 8px;
    &:focus-within {
      border-color: rgba(47, 128, 237, 0.5);
    }
    input {
      border: none !important;
    }
  }
  .multilineNonEditable {
    margin-top: 4px;
    margin-bottom: 4px;
    border: none;
    border-bottom: 1px solid rgba(106, 122, 152, 0.15) !important;
    border-radius: 0;
    input {
      border: none !important;
    }
  }

  .placeholder {
    div {
      position: relative;
      top: 2px;
      color: rgba(106, 122, 152, 0.7) !important;
      &:after {
        border: none !important;
      }
      &:focus {
        background: none;
      }
    }
  }
}

:global
  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.details_menuItem__1MuvI.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  background: none;
  svg {
    visibility: visible;
  }
  &:hover {
    background-color: #2f80ed;
    color: white;
    svg {
      background-color: #2f80ed;
    }
  }
}

.menuItem {
  height: 32px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0 8px;
  border-radius: 8px;
  color: #818c99;
  &:hover {
    background-color: #2f80ed;
    color: white;
  }
}

.switchLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.incrementContainer {
  height: 32px;
  margin-top: 6px;
  margin-bottom: 24px;

  .label {
    white-space: normal;
    font-weight: 400;
    font-size: 13px;
    position: relative;
    bottom: 26px;
    color: #25314f;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 46px;
    position: relative;
    button {
      background: none;
      height: 16px;
      border: none;
      border-left: 1px solid var(--color-table-nav);
      color: rgba(43, 56, 88, 0.5);
      &:focus {
        outline: none;
        border-color: rgba(47, 128, 237, 0.5);
      }
      svg {
        position: relative;
        bottom: 2px;
      }
    }
  }

  .inputContainer {
    height: 33px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(106, 122, 152, 0.15);
    border-radius: 8px;
    .input {
      width: 100%;
    }
    input {
      border: none !important;
    }
    &:focus-within {
      border: 1px solid rgba(47, 128, 237, 0.5) !important;
      button {
        border-color: rgba(47, 128, 237, 0.5) !important;
      }
    }
  }

  .inputContainerNonEditable {
    border: none;
    border-bottom: 1px solid rgba(106, 122, 152, 0.15) !important;
    border-radius: 0 !important;
  }

  .buttonDisabled {
    button {
      border: none !important;
    }
  }
}

.selectProfileDialog {
  border-radius: 16px;
  width: 380px;

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    :global .ant-checkbox-wrapper {
      margin: 7px 10px;
      max-width: fit-content;
    }

    .checkboxLabel {
      font-family: Golos;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1b1d21;
    }
  }

  button {
    height: 40px;
    margin: 20px 0;
    font-family: Lato;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.root.mTop {
  margin-top: 24px;
}

.dropdownIndex{
  z-index: 1500;
}

.footer {
  top: auto;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(106, 122, 152, 0.15);
  margin-left: var(--width-drawer);
  z-index: 990;

  .footerToolbar {
    height: 60px;
    display: flex;
    justify-content: flex-end;

    button {
      font-family: Lato;
      margin-right: 28px;
      padding: 10px 17px;
      text-transform: none;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 12px;
      box-shadow: none;
    }

    .deleteButton {
      color: var(--color-error);
      background: #f8f9fb;
      &:hover {
        color: #ff7875;
      }
    }

    .cancelButton {
      color: var(--color-accent);
      background: #f8f9fb;
      &:hover {
        color: #40a9ff;
      }
    }
  }
}

.inputWithError {
  border: 1px solid red !important;
  border-radius: 8px;

  div:first-child {
    &:hover {
      border-color: #d9d9d9;
    }
  }
}

.validationErrorText {
  font-size: 12px;
  color: #ff5a60;
}

.root_icon{
  padding: 10px;
  margin-bottom: 5px;
  &:hover{
    background-color: #fff;
  }
}

.popper_modal{
  width: 230px;
  padding: 5px 36px 5px 15px;
  background-color: #fff;
  color: #818C99;
}

.okButton {
  margin-left: 24px;
  width: 108px;
}

