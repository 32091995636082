.picker {
  border-radius: 18px !important;
  box-shadow: black;
}

.inputTime {
  width: 100%;
  border-radius: 8px;

  button {
    padding-right: 0;
  }
}

.validationErrorText {
  font-size: 12px;
  color: #ff5a60;
}

.inputWithError {
  border: 1px solid red !important;
  border-radius: 8px;
}

.input {
  border-radius: 8px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    border-right: 1.3px solid rgba(106, 122, 152, 0.15);
    right: 43px;
    top: 0;
  }
  &:hover,
  &:focus-within {
    cursor: pointer;
    border-color: #3a85ff;
    background-color: #f5f5f5;
  }
}

:global .ant-picker-cell-inner {
  border-radius: 8px !important;
  font-size: 16px;
  line-height: 24px;
}

:global .ant-picker-ok {
  width: 100%;
}

:global .ant-picker-footer-extra {
  padding: 0 16px;
}

:global .ant-picker-time-panel {
  display: none;
}

:global .ant-picker-ok button {
  border-radius: 12px !important;
  width: 100%;
  margin-top: 12px;
  font-size: 15px;
  background-color: #3a85ff !important;
  height: 48px !important;
  color: #fff !important;
}

:global .ant-picker-footer-extra:not(:last-child) {
  border-bottom: none;
}

.inputDisabled {
  border: none !important;
  span {
    display: none;
  }
}
