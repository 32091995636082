.root {
  display: flex;

  .appBar {
    box-shadow: none;
    z-index: 999;
    transition: 0.3s;
    width: calc(100% - 80px);
  }
  .appBarShift {
    margin-left: var(--width-drawer);
    width: calc(100% - var(--width-drawer));
  }

  .toolbar {
    height: 80px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    .backActionBtn {
      color: var(--color-accent);
      cursor: pointer;
    }

    .title {
      padding-left: 14px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }

    .menuButton {
      margin-right: 36px;
      right: 76px;
      color: white;
    }

    .menuButtonHidden {
      display: none;
    }

    .badgeColor {
      background: var(--color-yellow-gradient);
    }

    .avatar {
      margin-right: 6px;
      background: var(--color-yellow-gradient);
      border-radius: 10px;
    }

    .userBlock {
      font-family: Lato;
      font-style: normal;
      & :first-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #181920;
      }
      & :last-child {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #7b819b;
      }
    }

    .logout {
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .content {
    padding: 100px 32px 12px;
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
  }
}
