@font-face {
  font-family: Golos;
  font-weight: 400;
  src: url("fonts/Golos/Golos-Text_Regular.ttf");
}

@font-face {
  font-family: Golos;
  font-weight: 500;
  src: url("fonts/Golos/Golos-Text_Medium.ttf");
}

@font-face {
  font-family: Golos;
  font-weight: 600;
  src: url("fonts/Golos/Golos-Text_DemiBold.ttf");
}

@font-face {
  font-family: Golos;
  font-weight: 700;
  src: url("fonts/Golos/Golos-Text_Bold.ttf");
}

@font-face {
  font-family: Golos;
  font-weight: 900;
  src: url("fonts/Golos/Golos-Text_Black.ttf");
}
