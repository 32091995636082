.toolbar {
  &.center {
    display: flex;
    justify-content: center;
    //align-items: center;
  }

  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: var(--color-text-primary);

  .logo {
    max-width: 70px;
    margin-left: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .menuIcon {
    display: inline-flex !important;
    border: none;
    background: none;
    color: #ffffff;
    justify-content: center;

    &.menuOpened {
      margin-right: 5px;
    }
  }
}

.list {
  margin: 0 12px !important;

  .backToSchoolBorder {
    margin-bottom: 12px;
    background: #ffffff;
    opacity: 0.1;
  }

  .icon {
    color: #ffffff;
    min-width: auto;
    margin-right: 0.5em;
  }

  .iconClosed {
    color: #ffffff;
  }

  .root_text {
    display: flex;
    flex-direction: column;
  }

  .listItemText {
    color: #d7d9e1;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .listItemTextHidden {
    display: none !important;
  }

  .sectionTitle {
    display: flex;
    flex-direction: column;
  }

  .arrowRotate {
    transform: rotate(-90deg);
    color: #7b819b;
    transition: 0.3s;
  }

  .arrowBack {
    transform: rotate(0);
    transition: 0.3s;
    color: white;
  }

  .sectionTitle > span {
    font-family: Lato, sans-serif;
    color: var(--color-menu-item);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    align-items: start;
  }
}

div .drawerPaper {
  position: relative;
  white-space: nowrap;
  width: var(--width-drawer);
  color: var(--color-list-item);
  background-color: var(--color-brand-blue);
  transition: 0.4s;
  z-index: 999;
  height: 100vh;
}

div .drawerPaperClose {
  transition: 0.3s;
  background-color: var(--color-brand-blue);
  width: 80px;
}

.hideRow {
  height: 0;
  transition: all 1s;
}

.activeItemStyle {
  background-color: var(--color-accent) !important;
  border-radius: 8px !important;

  &:hover {
    @extend .activeItemStyle;
  }
}

.additionalInfo {
  width: 80%;
  margin: 32px 0 0 32px;
  padding-bottom: 32px;
}

.drawerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiDrawer-root {
  .MuiPaper-root {
    background-color: red;
  }
}
